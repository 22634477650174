.show-cell {
  background: #ffffff;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}

.cell-tooltip {
  position: absolute;
  max-width: 300px;
  max-height: 150px;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
  width: 170px;
  height: 80px;
}

.cell-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.cell-tooltip p {
  margin: 5px;
}

.cell-tooltip p:first-of-type {
  font-weight: bold;
}

.cell-tooltip-large {
  position: absolute;
  max-width: 300px;
  max-height: 150px;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
  width: 200px;
  height: 100px;
}

.cell-tooltip-large.ag-tooltip-hiding {
  opacity: 0;
}

.cell-tooltip-large p {
  margin: 5px;
}

.cell-tooltip-large p:first-of-type {
  font-weight: bold;
}

.ag-theme-alpine {
  --ag-row-hover-color: rgb(205, 208, 209);
  --ag-selected-row-background-color: rgb(205, 208, 209);
}

.ag-theme-alpine .ag-row-hover.ag-row-selected::before {
  background: rgb(205, 208, 209);
}

.ag-theme-alpine .ag-header-cell-comp-wrapper {
  overflow: visible;
}

.ag-theme-alpine-dark {
  --ag-row-hover-color: #8a8886;
  --ag-selected-row-background-color: #8a8886;
}

.ag-theme-alpine-dark .ag-row-hover.ag-row-selected::before {
  background: #8a8886;
}
