@import "./ag-grid-custom-theme-mixin.scss";
@import "./ag-grid-custom-theme-params.scss";

.ag-theme-alpine {
  .ag-cell-focus,
  .ag-cell {
    //border: none !important;
    font-family: $default-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: $default-text-color;
  }

  .ag-cell-right-aligned {
    display: inline-grid;
    text-align: right;
  }

  .ag-cell-center-aligned {
    display: inline-grid;
    text-align: center;
  }

  .ag-header-row {
    color: #636363 !important;
    background-color: #ececec;
    min-height: 36px;
    font-weight: 500;
    font-size: 14px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
  }

  .ag-header-viewport {
    background-color: #ececec;
  }

  .ag-row-hover {
    background: rgba(38, 50, 56, 0.23);
  }

  .ag-row-selected {
    background: rgba(38, 50, 56, 0.23);
  }

  .ag-row {
    border-color: #a7a7a7;
  }

  .ag-row-group .ag-group-value {
    padding-top: 11px;
  }

  .ag-row-level-1 .ag-row-drag {
    padding-left: 40px;
  }

  .ag-ltr .ag-row-level-1 .ag-row-group-leaf-indent {
    margin-left: 0px;
  }

  .ag-ltr .ag-row-group-indent-1 {
    padding-left: 0px;
  }

  @each $icon in $smallIcons {
    .ag-icon.ag-icon-#{$icon} {
      @include icon-background($icon-path, $icon, 12px);
      &:before {
        content: "" !important;
      }
    }
  }

  @each $icon in $largeIcons {
    .ag-icon.ag-icon-#{$icon} {
      @include icon-background($icon-path, $icon, 21px);
      &:before {
        content: "" !important;
      }
    }
  }

  @each $icon in $icons {
    .ag-icon.ag-icon-#{$icon} {
      @include icon-background($icon-path, $icon, 16px);
      &:before {
        content: "" !important;
      }
    }
  }

  .disable-group-expander .ag-icon-tree-closed {
    display: none !important;
  }

  .disable-header .ag-header {
    display: none !important;
  }
}

.ag-theme-alpine-dark {
  .ag-cell-focus,
  .ag-cell {
    //border: none !important;
    font-family: $default-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: $default-text-color;
  }
  .ag-header-row {
    background-color: #999999;
    min-height: 36px;
    font-weight: 500;
    font-size: 14px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
  }

  .ag-header-viewport {
    background-color: #999999;
  }

  .ag-row {
    background-color: #999999;
  }

  .ag-root-wrapper {
    background-color: #999999;
  }
}
