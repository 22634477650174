.Toastify__toast--error {
  background: #ef273c;
}
.Toastify__toast--success {
  background: #00a341;
}
.Toastify__toast-theme--light {
  color: var(--toastify-text-color-dark);
}
.Toastify__close-button--light {
  color: var(--toastify-text-color-dark);
  opacity: 0.7;
}
:root
{
--toastify-icon-color-error: var(--toastify-text-color-dark) 
}